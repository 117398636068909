import structApi from '@/api/services/structures';

export default {
  state: {
    devicesData: [],
  },
  getters: {
    devicesData: state => state.devicesData,
  },
  mutations: {
    devicesData: (state, value) => (state.devicesData = value),
  },
  actions: {
    async fetchDevicesData({ getters, commit }) {
      const hostId = getters.singleItem.id;
      const result = await structApi.getDevicesInfo(hostId);
      commit('devicesData', result);
      return result;
    },
  },
};
