<template>
  <PageLayout
    back-tooltip="Вернуться к списку осмотров"
    back-route="inspections:list"
    fluid
  >
    <template #title>
      <span>Карточка осмотра {{ item.id ? ' №' + item.id : '' }}</span>
      <v-chip
        v-if="singleItem.cleanedFiles"
        class="ml-1"
        color="teal darken-4"
        outlined
        small
      >
        Файлы просрочены
      </v-chip>
      <v-chip
        v-if="singleItem.cleanedVideos"
        class="ml-1"
        color="teal darken-4"
        outlined
        small
      >
        Видео просрочено
      </v-chip>
    </template>
    <v-col cols="12" sm="3">
      <v-card>
        <HistoryPhoto
          v-if="employee.photo"
          :photo="employee.photo.url"
          :date="singleItem.timestamps.finishedAt"
          :timezone-offset="singleItem.timezoneOffsetMin"
        />
        <v-card-title><span class="mr-3">Личные данные</span></v-card-title>
        <DefinitionList dense :value="employeeData" />
        <v-divider v-if="$can('I_RMI')" />
        <AverageMedData
          v-if="$can('I_RMI')"
          :boundaries="singleItem.boundaries"
          :average="measurementsHistory.average"
        />
      </v-card>
    </v-col>
    <v-col cols="12" sm="5">
      <v-card class="d-flex">
        <Plyr v-if="item.video" :url="item.video" autoplay download />
        <v-card-text v-else class="d-flex align-center justify-center">
          <v-alert class="flex-fill ma-0" type="error" rounded dense outlined>
            Видео не найдено
          </v-alert>
        </v-card-text>
      </v-card>
      <v-layout class="mt-3" style="height: 100px">
        <BigBtn
          v-if="$can('EMP_RD')"
          icon="fa-eye"
          text="Карточка работника"
          :to="{ name: 'employees:card', params: { id: employee.id } }"
          :disabled="!$can('EMP_RD') || isRestricted"
        />
        <BigBtn
          v-if="$can('SKR_RE')"
          class="ml-5"
          icon="fa-print"
          text="Печать стикера"
          @click="openModalPrintSticker"
        />
        <BigBtn
          v-if="$can('I_R')"
          class="ml-5"
          icon="fa-history"
          text="История осмотров"
          @click="showInspectionsHistoryModal"
        />
      </v-layout>
    </v-col>
    <v-col cols="12" sm="4">
      <v-card v-if="$can('I_RMI')" class="mb-4">
        <v-card-title>
          <span class="mr-3">Медицинские показатели</span>
        </v-card-title>
        <v-card-text>
          <Scenario
            dense
            :steps="item.steps"
            :remarks="item.resolution.remarks"
          />
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-title>
          <span class="mr-3">Подробности осмотра</span>
        </v-card-title>
        <InspectionDetails
          :inspection="singleItem"
          :remarks="inspectionRemarks"
          :remark-types="inspectionRemarkTypes"
          show-error-notifications
        />
      </v-card>
      <v-card v-if="singleItem.ambientConditions" class="mb-4">
        <v-card-title>
          <span class="mr-3">Параметры окружающей среды</span>
        </v-card-title>
        <EnvironmentParams :ambient-conditions="singleItem.ambientConditions" />
      </v-card>
      <v-card v-if="$can('I_RIA') || $can('I_DIA')" class="mb-4">
        <v-card-title>
          <span class="mr-3">Контроль качества</span>
        </v-card-title>
        <Approval :value="singleItem.approval" :inspection-id="singleItem.id" />
      </v-card>

      <v-card v-if="$can('ODC_R')" class="mb-4" style="overflow: hidden">
        <AsyncWrapper :handler="fetchOutgoingDocs">
          <AverageMedData
            :outgoing="outgoingDocs"
            :average="measurementsHistory.average"
            doc-title="Сформированные документы"
          />
        </AsyncWrapper>
      </v-card>

      <v-card
        v-show="$can('SUR_GET') && surveys.length && surveyText"
        class="mb-4"
      >
        <v-card-title>
          <span class="mr-3">Прием запрещенных препаратов</span>
        </v-card-title>
        <AsyncWrapper :handler="fetchEmployeeSurveys">
          <v-card-text>{{ surveyText }}</v-card-text>
        </AsyncWrapper>
      </v-card>

      <v-card v-if="$can('INS_DET')" class="mb-4">
        <v-card-title>
          <span class="mr-3">История прохождения осмотра</span>
        </v-card-title>
        <AsyncWrapper
          :handler="fetchProcessingInspectionDetails.bind(null, item.id)"
        >
          <ProcessingDetails
            v-if="processingInspectionDetails"
            :value="processingInspectionDetails"
            hide-inspection-number
          />
          <div v-else class="text-center py-3">Нет пройденных осмотров</div>
        </AsyncWrapper>
      </v-card>

      <v-card v-if="integrations.length" class="mb-4">
        <v-card-title>
          <span class="mr-3">Интеграция с внешними системами</span>
        </v-card-title>
        <Integrations :integrations="integrations" />
      </v-card>
    </v-col>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import waitable from '@/utils/mixins/waitable';
import { convert } from '@/utils/entities';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import surveyApi from '@/api/services/survey';

import { entity as entityEmployee } from '@/modules/employees/entity';
import structApi from '@/api/services/structures';

import PageLayout from '@/components/layouts/PageLayout';
import AverageMedData from '@/components/AverageMedData.vue';
import DefinitionList from '@/components/DefinitionList';
import BigBtn from '@/components/controls/buttons/BigBtn';
import Plyr from '@/components/Plyr';
import HistoryPhoto from '@/components/HistoryPhoto';
import InspectionDetails from '@/modules/inspections/components/InspectionDetails';
import Scenario from '@/modules/medcab/components/Scenario';
import AsyncWrapper from '@/components/AsyncWrapper';
import ProcessingDetails from '@/components/ProcessingDetails';
import LinkButton from '@/components/controls/buttons/LinkButton';
import LinkButtonList from '@/components/controls/buttons/LinkButtonList';
import Approval from '@/modules/inspections/components/Approval';
import EnvironmentParams from '@/modules/inspections/components/EnvironmentParams';
import Integrations from '@/modules/inspections/components/Integrations';

export default {
  components: {
    EnvironmentParams,
    PageLayout,
    DefinitionList,
    AverageMedData,
    BigBtn,
    HistoryPhoto,
    Plyr,
    InspectionDetails,
    Scenario,
    AsyncWrapper,
    ProcessingDetails,
    Approval,
    Integrations,
  },
  mixins: [waitable, routeGuardMixin],

  data: () => ({
    // NOTE: is current user restricted from viewing this inspection employee
    // card true by default for security reasons, so the user can't slip in when
    // the request hasn't been resolved yet
    isRestricted: true,
    surveys: [],
  }),

  computed: {
    ...mapGetters('REGISTRY', ['inspectionRemarks', 'inspectionRemarkTypes']),

    ...mapGetters('INSPECTION', [
      'employee',
      'medRecord',
      'measurementsHistory',
      'outgoingDocs',
      'processingInspectionDetails',
    ]),

    employeeData() {
      if (!this.employee) return [];

      const emp = convert(entityEmployee, this.employee);
      const empOriginal = this.employee;

      const groupButtons = {
        label: 'Группы',
        component: LinkButtonList,
        props: {
          list: empOriginal.groups.map(el => ({
            text: el.name || `#${el.id}`,
            action: this.$can('EMG_RD'),
            to: {
              name: 'structures:employee_groups:item',
              params: { id: el.id },
            },
          })),
        },
      };

      return [
        { label: 'ФИО', value: emp.name },
        {
          label: 'ID',
          component: LinkButton,
          props: {
            text: `#${emp.id}`,
            action: this.$can('EMP_RD'),
            to: { name: 'employees:card', params: { id: emp.id } },
          },
        },
        { label: 'Дата рождения', value: emp.dateOfBirth },
        { label: 'Возраст', value: emp.age },
        { label: 'Пол', value: emp.gender },
        { label: 'Табельный №', value: emp.personnelNumber },
        { label: 'Телефон', value: emp.phone },
        {
          label: 'Организация',
          component: LinkButton,
          props: {
            text:
              empOriginal.organization.name ||
              `#${empOriginal.organization.id}`,
            action: this.$can('ORG_RD'),
            to: {
              name: 'structures:organizations:item',
              params: { id: empOriginal.organization.id },
            },
          },
        },
        ...((empOriginal.groups.length && [groupButtons]) || []),
      ].filter(_ => _);
    },

    integrations() {
      return (
        [...this.item.integrations].sort(
          (a, b) => new Date(a.occurredAt) - new Date(b.occurredAt),
        ) || []
      );
    },

    surveyText() {
      const key = 'illegal_drugs';
      const answer = this.surveys[0]?.answers.find(a => a.key === key);
      return answer ? answer.value || '-' : false;
    },

    loading() {
      return (
        this.$wait('fetchingInspectionRemarks') ||
        this.$wait('fetchingRecord') ||
        this.$wait('fetchingHistory')
      );
    },
  },

  watch: {
    employee: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => val && this.fetchEmployeeExtendedInformation());
      },
    },
  },

  async created() {
    await this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchInspectionRemarks'),
      'fetchingInspectionRemarks',
      'Произошла ошибка загрузки меток',
    );
    this.isRestricted = (
      await structApi.getPermissionToReadEmployee(this.employee.id)
    ).isRestricted;
  },

  methods: {
    ...mapActions('INSPECTION', [
      'fetchOutgoingDocs',
      'fetchProcessingInspectionDetails',
    ]),

    openModalPrintSticker() {
      const props = {
        inspectionId: this.item.id,
        organizationId: this.item?.organization.id,
        hostId: this.item?.host.id,
      };
      this.$openModal('inspections/printSticker', props);
    },

    async fetchEmployeeSurveys() {
      if (!this.$can('SUR_GET')) return;

      const params = {
        orderBy: 'finishedAt',
        orderType: 'DESC',
        refererId: this.employee.id,
        inspectionId: this.singleItem.id,
        page: 1,
        limit: 5,
      };

      const { items } = await this.$loading(
        surveyApi.getEmployeeSurveys(params),
        'fetchEmployeeSurveys',
      );

      this.surveys = items;
    },

    showInspectionsHistoryModal() {
      this.$openModal('inspections/history', {
        employee: this.singleItem.employee,
      });
    },

    fetchEmployeeExtendedInformation() {
      if (!this.employee) return;

      this.$loadingNotify(
        this.$store.dispatch('INSPECTION/getMedRecord', this.employee.id),
        'fetchingRecord',
        'Произошла ошибка загрузки медицинских записей',
      );
      this.$loadingNotify(
        this.$store.dispatch(
          'INSPECTION/getEmployeeAverages',
          this.employee.id,
        ),
        'fetchingHistory',
        'Произошла ошибка загрузки средних показателей',
      );
    },
  },
};
</script>
