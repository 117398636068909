<template>
  <div>
    <v-form v-if="!need2FAuthorization" @submit.prevent>
      <v-text-field
        v-model="username"
        label="Логин"
        outlined
        depressed
        dense
        tabindex="1"
        autofocus
        persistent-placeholder
        :disabled="softAuth || $wait('login')"
        :error-messages="getValidationErrors('username')"
        :error="!!authError"
        @input="authError = null"
      />
      <v-text-field
        v-model.trim="password"
        label="Пароль"
        name="password"
        type="password"
        outlined
        depressed
        dense
        tabindex="2"
        persistent-placeholder
        :disabled="$wait('login')"
        :error-messages="authError || getValidationErrors('password')"
        @input="authError = null"
      />
      <v-card-actions class="pt-0 d-flex justify-center">
        <v-btn
          v-if="softAuth"
          class="px-10"
          :disabled="$wait('login')"
          height="40"
          color="primary"
          outlined
          @click="signOut"
        >
          Выйти
        </v-btn>
        <v-btn
          type="submit"
          class="px-5"
          :loading="$wait('login')"
          height="40"
          color="primary"
          @click="onSubmit"
        >
          {{ softAuth ? 'Продолжить' : 'Войти' }}
        </v-btn>
      </v-card-actions>

      <div v-if="!softAuth" class="text-center mt-2">
        <v-btn
          v-for="[testUsername, testPassword] in testCredentials"
          :key="testUsername"
          :disabled="$wait('login')"
          depressed
          x-small
          @click="signInBy(testUsername, testPassword)"
        >
          Log in by {{ testUsername }}
        </v-btn>
      </div>
    </v-form>
    <ByEmailCode
      v-if="needEmailCode"
      @authSuccess="onEmailCodeSuccess($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import ByEmailCode from './ByEmailCode.vue';

export default {
  components: {
    ByEmailCode,
  },
  mixins: [validationMixin, waitable],

  validations() {
    return {
      username: {
        required,
        email,
        minSymbolsLength: minLength(3),
      },
      password: {
        required,
        minSymbolsLength: minLength(3),
      },
    };
  },

  props: {
    matchBy: { type: [String, Object], default: null },
    signIn: { type: Function, required: true },
    signOut: { type: Function, default: null },
  },

  data: () => ({
    username: '',
    password: '',
    authError: null,
  }),

  computed: {
    ...mapGetters('AUTH', [
      'testCredentials',
      'isBasic',
      'needEmailCode',
      'need2FAuthorization',
    ]),

    softAuth() {
      return !!this.matchBy;
    },
  },

  watch: {
    // For comfortable development
    testCredentials: {
      immediate: true,
      handler(value) {
        if (!this.matchBy) return;
        const credentials = value.find(([key]) => key === this.matchBy) || [];
        this.password = credentials[1];
      },
    },
  },

  created() {
    if (this.matchBy) this.username = this.matchBy;
  },

  methods: {
    ...mapActions('AUTH', ['cancel2FA']),
    signInBy(username = 'superuser', password = 'admin') {
      this.username = username;
      this.password = password;
      this.onSubmit();
    },

    async onSubmit() {
      this.authError = null;
      this.$v.$touch();

      if (this.$v.$invalid) return null;

      try {
        await this.$loading(
          this.signIn({
            username: this.username,
            password: this.password,
          }),
          'login',
        );
        this.password = '';
        this.$v.$reset();
      } catch (error) {
        this.$v.$reset();

        console.error(error);
        const status = error?.response?.status || null;
        const messagesStatus = {
          401: 'Неверный пароль или логин',
          403: 'Доступ заблокирован',
          404: 'Пользователя с такой почтой не существует',
        };

        if (error.message === 'Network Error')
          this.authError =
            'Ошибка сети. Проверьте интернет соединение или обратитесь в ТП';
        else if (error.message === 'no_certificate')
          this.authError = 'Сертификат не установлен. Установите сертификат.';
        else if (error.message === 'esia_error')
          this.authError = 'Ошибка авторизации через Госуслуги';
        else if (error.type === 'esia_error') this.authError = error.message;
        else if (/.*disabled.*/.test(error.response?.data?.message))
          this.authError = 'Аккаунт деактивирован';
        else if (messagesStatus[status])
          this.authError = messagesStatus[status];
        // Ignore error when interrupt 2FA
        else if (error.message === '2fa_aborted')
          console.error('2FA was interrupted');
        else this.authError = 'Произошла ошибка авторизации';
      }
    },
    onEmailCodeSuccess(booleanValue) {
      !booleanValue && this.cancel2FA();
    },
  },
};
</script>
