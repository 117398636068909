<template>
  <tr style="vertical-align: top">
    <td class="py-2">
      <TittledText :subtitle="value.type.name">
        <template #title>
          <div class="d-flex align-center">
            #{{ value.id }}
            <v-chip
              v-if="value.isImported"
              class="ml-1"
              color="teal darken-4"
              outlined
              small
            >
              Архив
            </v-chip>
            <v-chip
              v-if="value.cleanedFiles"
              class="ml-1"
              color="teal darken-4"
              outlined
              small
            >
              Файлы просрочены
            </v-chip>
            <v-chip
              v-if="value.cleanedVideos"
              class="ml-1"
              color="teal darken-4"
              outlined
              small
            >
              Видео просрочено
            </v-chip>
          </div>
        </template>
        <div>{{ clientDateTime }}</div>
        <TerminalDateTime
          :date="value.timestamps.processedAt"
          :offset="value.timezoneOffsetMin"
        />
        <div>{{ value.organization.name }}</div>
        <div v-if="hostInfo">ПАК {{ hostInfo }}</div>
        <Badges :value="inspectionBadges" />
      </TittledText>
    </td>
    <td class="py-2">
      <TittledText
        :title="name(value.employee)"
        :subtitle="`Возраст: ${employeeAge} (${dateOfBirth})`"
      >
        <div>Пол: {{ gender }}</div>
        <div>Табельный №: {{ value.employee.personnelNumber }}</div>
        <div>Телефон: {{ maskTextPhone(value.employee.phone) || '–' }}</div>
      </TittledText>
    </td>
    <td class="py-2">
      <v-layout fill-height>
        <div class="inspection__resolution mr-1">
          <ResultBadge
            class="mb-4"
            :result="value.resolution.success"
            :remarks="value.resolution.remarks"
            :remark-types="remarkTypes"
            :type="value.type"
          />
          <div>{{ resolverName }}</div>
          <div>{{ remarkString }}</div>
          <div v-if="value.resolution.comment" class="mt-2">
            Комментарий: {{ value.resolution.comment }}
          </div>
          <div v-if="$can('I_RIA')" class="mt-4 d-flex">
            <span class="flex-grow-1 flex-shrink-0 font-weight-bold">
              Контроль качества:&nbsp;
            </span>
            <ColoredText
              class="flex-shrink-1"
              :color="approvalStatusColor"
              :text="approvalStatusStr"
            />
          </div>
        </div>
        <div v-if="$can('I_RMI')" class="inspection__scenario">
          <Scenario
            :columns="2"
            :steps="value.steps"
            :remarks="value.resolution.remarks"
          />
        </div>
      </v-layout>
    </td>
    <td class="py-2">
      <div class="d-flex align-end flex-column">
        <v-tooltip v-if="$can('I_RD')" left>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              :to="{
                name: value.isImported
                  ? 'inspections:card:archived'
                  : 'inspections:card',
                params: { id: value.id },
              }"
              v-on="on"
            >
              <v-icon small>fa-eye</v-icon>
            </v-btn>
          </template>
          <span>Карточка</span>
        </v-tooltip>
        <v-tooltip v-if="$can('SKR_RE')" left>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="openModalPrintSticker(value)"
            >
              <v-icon small>fa-print</v-icon>
            </v-btn>
          </template>
          <span>Печать стикера</span>
        </v-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
import { diffYears } from '@/utils/helpers';
import { date, dateTime, gender, name, shortName } from '@/utils/convert';
import { maskTextPhone } from '@/utils/masks';

import TittledText from '@/components/TittledText';
import TerminalDateTime from '@/components/TerminalDateTime.vue';
import ResultBadge from '@/modules/inspections/components/ResultBadge';
import Scenario from '@/modules/medcab/components/Scenario';
import Badges from '@/modules/inspections/components/Badges';
import { getInspectionBadges } from '@/modules/inspections/badges';
import ColoredText from '@/modules/documents/incoming/components/ColoredText';

export default {
  components: {
    TittledText,
    TerminalDateTime,
    ResultBadge,
    Scenario,
    Badges,
    ColoredText,
  },
  props: {
    value: { type: Object, required: true },
    remarks: { type: Array, required: true },
    remarkTypes: { type: Object, required: true },
    timezone: { type: Number, default: null },
  },
  computed: {
    clientDateTime() {
      return dateTime(this.value.timestamps.processedAt, {
        timezone: this.timezone,
      });
    },
    dateOfBirth() {
      return date(this.value.employee.dateOfBirth);
    },
    employeeAge() {
      return diffYears(
        this.value.employee.dateOfBirth,
        new Date(this.value.timestamps.startedAt),
      );
    },
    gender() {
      return gender(this.value.employee.gender);
    },
    resolverName() {
      const { type, name } = this.value.resolvedBy;
      if (type === 'human')
        return `Медработник: ${shortName(this.value.resolvedBy)}`;
      else if (type === 'bot') return `Бот: ${name}`;
      return `${type}: ${name}`;
    },
    remarkString() {
      const name =
        (this.value.resolution.success ? 'Метки' : 'Причина недопуска') + ': ';
      const inspectionRemarks = this.value.resolution.remarks;
      if (!inspectionRemarks.length) return name + '-';

      const inspectionRemarksInline = inspectionRemarks
        .map(el => this.remarks.find(remark => remark.id === el)?.name)
        .filter(a => a)
        .join(', ');

      return name + inspectionRemarksInline;
    },

    approvalStatusStr() {
      return this.value.approval?.isInspected ? 'Проверен' : 'Не проверен';
    },
    approvalStatusColor() {
      if (!this.value.approval?.isInspected) {
        return 'grey darken-1';
      }
      return this.value.approval?.isApproved
        ? 'green darken-1'
        : 'red darken-2';
    },

    inspectionBadges() {
      return getInspectionBadges(this.value);
    },

    hostInfo() {
      const { license } = this.value?.host;
      return (
        [
          license ? license.split('-')[1] : null,
          this.value?.host?.releasePoint?.address ||
            '#' + this.value?.host?.releasePoint?.id,
        ]
          .filter(_ => _)
          .join(', ') || '–'
      );
    },
  },

  methods: {
    name,
    maskTextPhone,

    openModalPrintSticker(item) {
      const props = {
        inspectionId: item.id,
        organizationId: item?.organization.id,
        hostId: item?.host.id,
      };

      this.$openModal('inspections/printSticker', props);
    },
  },
};
</script>
<style lang="scss">
.inspection {
  &__resolution {
    min-width: 250px;
    max-width: 400px;
    width: 45%;
    flex-basis: 100%;
  }

  &__scenario {
    width: 55%;
    flex-basis: 100%;
  }
}
</style>
