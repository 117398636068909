<template>
  <v-dialog :value="true" max-width="450" persistent>
    <v-card>
      <v-card-title>Ваша сессия истекла</v-card-title>
      <v-card-text>
        <p>Необходимо выполнить авторизацию.</p>
        <component
          :is="component"
          v-if="component"
          :match-by="matchBy"
          :sign-in="onSubmit"
          :sign-out="signOut"
        />
        <v-btn v-else color="primary" @click="signOut">Выйти</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import authForms from '@/components/auth';

export default {
  computed: {
    ...mapGetters('AUTH', ['currentAccount']),
    type() {
      return this.currentAccount.loginType;
    },
    matchBy() {
      return this.currentAccount.matchBy;
    },
    component() {
      return authForms[this.type];
    },
  },

  methods: {
    ...mapActions('AUTH', ['signIn', 'signOut']),
    async onSubmit(credentials) {
      await this.signIn({ credentials, type: this.type });
    },
  },
};
</script>

<style></style>
