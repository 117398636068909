<template>
  <v-form @submit.prevent>
    <text-field
      v-model="email_code"
      label="Код подтверждения"
      :error="authError || getValidationErrors('email_code')"
      :disabled="$wait(['createEmailCode', 'checkEmailCode'])"
      type="number"
      :max-length="6"
      @input="$v.$touch()"
    />

    <v-card-actions class="pt-0 d-flex flex-wrap justify-center">
      <v-btn
        :loading="$wait(['createEmailCode', 'checkEmailCode'])"
        height="40"
        color="primary"
        :disabled="timerStatusToRedeemEmailCode"
        @click="onCreateEmailCode"
      >
        {{ createEmailCodeBtnText }}
      </v-btn>

      <div class="btns-container">
        <v-btn
          :disabled="$wait('checkEmailCode')"
          height="40"
          color="primary"
          outlined
          @click="goBack"
        >
          Назад
        </v-btn>
        <v-btn
          type="submit"
          :disabled="$v.$invalid || email_code.length === 0"
          :loading="$wait(['createEmailCode', 'checkEmailCode'])"
          height="40"
          color="primary"
          @click="onSubmit"
        >
          Продолжить
        </v-btn>
      </div>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import validationMixin from '@/utils/validation';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { timer } from '@/utils/timer';
import TextField from '../ui/TextField.vue';

// Количество неправильного ввода кода перед тем как полностью разлогинить пользователя
const MAX_TRIES = 3;

export default {
  components: { TextField },
  mixins: [validationMixin, waitable],

  data: () => ({
    email_code: '',
    authError: null,
    invalidTries: 0,
    timerToRedeemEmailCodeInstance: null,
    emailCodeTTLTimer: null,
    timerToRedeemEmailCode: null,
  }),

  computed: {
    ...mapGetters('AUTH', ['isAuthorized', 'softSignIn', 'emailCodeAccess']),
    redeemCodeAfter() {
      return (
        String(Math.floor(this.timerToRedeemEmailCode / 60)).padStart(2, '0') +
        ':' +
        String(this.timerToRedeemEmailCode % 60).padStart(2, '0')
      );
    },
    timerStatusToRedeemEmailCode() {
      return !!this.timerToRedeemEmailCodeInstance;
    },
    createEmailCodeBtnText() {
      return this.timerStatusToRedeemEmailCode
        ? `Запросить код повторно через ${this.redeemCodeAfter}`
        : 'Запросить код повторно';
    },
  },

  validations() {
    return {
      email_code: {
        required,
        minSymbolsLength: minLength(6),
        numeric,
      },
    };
  },

  beforeMount() {
    this.onCreateEmailCode();
  },

  methods: {
    ...mapActions('AUTH', [
      'signIn',
      'signOut',
      'createEmailCode',
      'checkEmailCode',
    ]),

    async onSubmit() {
      this.authError = null;

      if (!this.validate()) return null;
      const code = this.email_code;
      this.email_code = '';
      this.$v.$reset();

      try {
        const { success, error } = await this.$loading(
          this.checkEmailCode({ code, access: this.emailCodeAccess }),
          'checkEmailCode',
        );

        if (success) {
          this.$emit('authSuccess', true);
          this.$store.dispatch('initApplication');
        } else {
          const errors = {
            invalid: 'Неверный код подтверждения',
            not_found: 'Превышено время ожидания: запросите код повторно',
          };

          if (errors[error]) {
            this.authError = errors[error];
          } else this.authError = 'Ошибка: ' + error;

          this.email_code = '';
          this.invalidTries++;
          if (this.invalidTries === MAX_TRIES) this.goBack();
        }
        this.emailCodeTTLTimer.stop();
      } catch (e) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Не удалось отправить код',
          text: 'Попробуйте снова',
        });

        throw e;
      }
    },

    async onCreateEmailCode() {
      try {
        const response = await this.$loading(
          this.createEmailCode(this.emailCodeAccess),
          'createEmailCode',
        );

        this.invalidTries = 0;

        if (this.emailCodeTTLTimer) this.emailCodeTTLTimer.stop();
        this.emailCodeTTLTimer = timer({
          seconds: response.codeTTLseconds,
          onTimeout: () => {
            this.$notify({
              group: 'note',
              type: 'error',
              title: 'Время действия кода истекло',
            });
            this.emailCodeTTLTimer = null;
            this.goBack();
          },
        });

        this.setTimerToRedeemEmailCode();

        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Код авторизации успешно создан и отправлен Вам на почту',
        });

        return response;
      } catch (e) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Не удалось создать код верификации',
          text: 'Попробуйте создать код повторно',
        });

        throw e;
      }
    },

    setTimerToRedeemEmailCode(seconds = 60) {
      this.timerToRedeemEmailCodeInstance = timer({
        seconds,
        onTick: time => {
          this.timerToRedeemEmailCode = time;
        },
        onTimeout: () => {
          this.timerToRedeemEmailCodeInstance = null;
        },
        isStopped: false,
      });
    },

    goBack() {
      if (this.softSignIn) {
        if (this.emailCodeTTLTimer) this.emailCodeTTLTimer.stop();
        if (this.timerToRedeemEmailCodeInstance)
          this.timerToRedeemEmailCodeInstance.stop();
        this.$emit('authSuccess', false);
      } else this.signOut();
    },
  },
};
</script>

<style lang="scss" scoped>
.btns-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  gap: 12px;
}
</style>
