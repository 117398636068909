<template>
  <v-simple-table dense>
    <colgroup>
      <col width="auto" />
      <col width="auto" />
      <col width="auto" />
      <col width="120px" />
      <col width="120px" />
    </colgroup>
    <thead>
      <tr>
        <th class="py-3">Тип устройства</th>
        <th class="py-3">Модель</th>
        <th class="py-3">Данные</th>
        <th class="py-3">Дата и время создания</th>
        <th class="py-3">Дата и время обновления</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, key) in devicesMap" :key="key">
        <td>{{ item }}</td>
        <td>{{ dataTable[key].data.model || '-' }}</td>
        <td>
          <p class="mb-0">
            Серийный номер: {{ dataTable[key].data.serialNum || '-' }}
          </p>
          <p v-show="'firmwareVersion' in dataTable[key].data" class="mb-0">
            Версия прошивки: {{ dataTable[key].data.firmwareVersion || '-' }}
          </p>
        </td>
        <td>
          {{ dateTime(dataTable[key].created, { showSeconds: true }) || '-' }}
        </td>
        <td>
          {{ dateTime(dataTable[key].updated, { showSeconds: true }) || '-' }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { dateTime } from '@/utils/convert';

const DEVICES_MAP = {
  alcohol: 'Алкотестер',
  tonometry: 'Тонометр',
  temperature: 'Термометр',
};
export default {
  props: {
    data: { type: Array, default: () => [] },
  },

  computed: {
    devicesMap: () => DEVICES_MAP,
    dataTable() {
      return Object.fromEntries(
        Object.keys(this.devicesMap).map(key => {
          const dataItem = this.data.find(item => item.deviceType === key);
          return [key, dataItem || { data: {} }];
        }),
      );
    },
  },
  methods: { dateTime },
};
</script>
