<template>
  <div :class="['text-field', errorValue ? '--error' : '']">
    <input
      class="text-field__input"
      v-bind="{ error, disabled, value }"
      type="text"
      @input.prevent.stop="onInput"
    />
    <label v-if="label" for="input" class="text-field__label">{{
      label
    }}</label>
    <span class="text-field__error-text">{{ errorValue }}</span>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
      validator: v => ['text', 'number'].includes(v),
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: [String, Array],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    errorValue() {
      if (typeof this.error === 'string') {
        return this.error;
      } else if (Array.isArray(this.error)) {
        return this.error.join('\n');
      } else return '';
    },
  },

  methods: {
    onInput(event) {
      event.preventDefault();
      event.stopPropagation();

      let value = event.target.value;
      if (this.maxLength > 0) {
        value = value.slice(0, this.maxLength);
      }
      if (this.type === 'number') {
        value = value.replaceAll(/[^0-9]/g, '');
      }
      this.$emit('input', value);
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
$light-black: rgba(0, 0, 0, 0.3);
$black: rgba(0, 0, 0, 0.87);
$blue: #2196f3;
$red: #ff564e;

::v-deep input[type='number']::-webkit-inner-spin-button,
::v-deep input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  margin: 0 !important;
}

.text-field {
  $this: &;

  font-size: 16px;
  color: $light-black;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  margin-top: 12px;

  &__input {
    width: 100%;
    min-height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid $light-black;
    outline: none;

    #{$this}.--error & {
      border-color: $red;
    }

    &:hover {
      border-color: $black;
      color: $black;
    }

    &:focus {
      border-color: $blue;
      color: $black;
    }

    &:hover ~ label {
      color: $black;
    }

    &:focus ~ label {
      color: $blue;
    }
  }

  &__label {
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 10px;
    height: 12px;
    line-height: 12px;
    padding: 0 2px;
    background-color: #fff;
    transform: translateY(-50%);

    #{$this}.--error & {
      color: $red;
    }
  }

  &__error-text {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    min-height: 12px;
    padding: 0 12px;
    color: $red;

    #{$this}.--error & {
      color: $red;
    }
  }
}
</style>
