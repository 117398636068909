import { getCertificate, createAttachedSignature } from '@libs/crypto-pro';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import axios from '@/plugins/axios';
import { createHTTP } from '@/api/api';
import env from '@/plugins/env';
import { createAuthorizedHTTPHeader } from '@/utils/helpers';

const AUTH_API = env.get('VUE_APP_AUTH_API');
export const auth = createHTTP(AUTH_API);

const Accounts = {
  // users
  ...resourceServiceFactory(auth, 'account', ['status']),

  getExists(params) {
    return auth.get(`/accounts/exists`, { params }).then(res => res.data);
  },

  profileUpdate(params) {
    return auth
      .put(`/accounts/${params.id}/profile`, params.profile)
      .then(res => res.data);
  },

  profilePushUpdate(id, params) {
    return auth
      .put(`/accounts/${id}/waybill-settings`, params)
      .then(res => res.data);
  },

  resetPassword(id) {
    return auth.post(`/accounts/${id}/password/reset`);
  },

  updateRole(id, roleKey) {
    return auth.put(`/accounts/${id}/role`, { roleKey }).then(res => res.data);
  },

  updateEmail(id, email) {
    return auth.put(`/accounts/${id}/email`, { email }).then(res => res.data);
  },

  changeBindings(id, bindings) {
    return auth.put(`/accounts/${id}/bindings`, bindings).then(res => res.data);
  },

  accountImport({ file, onlyCreate }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('onlyCreate', onlyCreate);

    return auth.post('/accounts/import', formData).then(res => res.data);
  },

  // applications
  ...resourceServiceFactory(auth, 'apps', ['status']),

  addApiKey(id, expiresAt) {
    return auth.post(`/apps/${id}/keys`, { expiresAt }).then(res => res.data);
  },
  deactivateApiKey(keyId) {
    return auth.delete(`/apps/keys/${keyId}`).then(res => res.data);
  },

  /**
   * Отключение уведомлений об истечении срока действия ключа
   * @action APP_RAK
   * @param {string} keyId - идентификатор ключа
   * @param {string} expiresAt - дата, до которой отключить уведомления дата в формате ISO 8601 ('2023-01-01T00:00:000.000Z')
   */
  ignoreExpiredErrorApiKey(keyId, expiresAt) {
    return auth
      .post(`/apps/keys/${keyId}/ignore_expired_error`, { until: expiresAt })
      .then(res => res.data);
  },

  // my account
  getMyAccount() {
    return auth.get(`/my/account`).then(res => res.data);
  },
  getMyMedicAccount() {
    return auth.get(`/my/medics/account`).then(res => res.data);
  },
  resetMyPassword() {
    return auth.post(`/my/password/reset`);
  },
};

const Medics = {
  getMedic(id) {
    return auth.get(`/medics/${id}/account`).then(res => res.data);
  },

  getMedicAccounts(ids) {
    const params = {
      medicIds: ids.join(','),
    };
    return auth.get(`/medics/accounts`, { params }).then(res => res.data);
  },

  createMedic(id) {
    return auth.post(`/medics/${id}/account`).then(res => res.data);
  },

  changeMedicEmail(medicId, email) {
    const params = {
      email,
    };
    return auth.put(`/medics/${medicId}/email`, params);
  },
  resetMedicPassword(medicId) {
    return auth.post(`/medics/${medicId}/password/reset`);
  },

  getRole(id) {
    return auth.get(`/roles/${id}`).then(res => res.data);
  },

  getAccountPreviews(params) {
    return auth.get('/accounts', { params }).then(res => res.data);
  },
  getAccountPreviewsBy(ids) {
    return auth
      .get('/accounts/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },
};

export default {
  ...Accounts,
  ...Medics,

  logInGeneral(credentials, type = 'email_pwd') {
    const data = {
      credentials,
      type,
    };

    return axios
      .post(`${AUTH_API}/auth/general/login`, data)
      .then(data => data.data);
  },

  esiaAuthUrl(redirectUrl) {
    return axios
      .get(`${AUTH_API}/esia/login-url?redirectURI=${redirectUrl}`)
      .then(data => data.data);
  },

  esiaAuth(code, email, redirectURI) {
    return axios
      .post(`${AUTH_API}/esia/login`, { code, email, redirectURI })
      .then(data => data.data);
  },

  async getCryptoParams({ thumbprint, type }) {
    const cert = await getCertificate(thumbprint);
    const serial = await cert.getCadesProp('SerialNumber');
    const { sample } = await axios
      .get(`${AUTH_API}/v3/cryptopro/sample`, {
        params: { serial },
      })
      .then(data => data.data);

    let encryptedSample;
    try {
      encryptedSample = await createAttachedSignature(thumbprint, sample);
    } catch (err) {
      err.message = 'КриптоПро CSP: ' + err.message;
      throw err;
    }
    return {
      sample,
      encryptedSample,
      type,
    };
  },

  async logInCryptoPro({ thumbprint, type }) {
    const data = await this.getCryptoParams({ thumbprint, type });
    return axios
      .post(`${AUTH_API}/v3/cryptopro/login`, data)
      .then(data => data.data);
  },

  refreshTokens(refreshToken, { userId, type = 'email_pwd', matchBy }) {
    const data = {
      refreshToken,
      userId,
      loginDetails: {
        type,
        matchBy,
      },
    };

    return axios
      .post(`${AUTH_API}/auth/tokens/refresh`, data)
      .then(data => data.data);
  },

  fetchRolesPreviews(params) {
    return auth.get('/roles/previews', { params }).then(res => res.data);
  },

  ...resourceServiceFactory(auth, 'roles'),
  ...resourceServiceFactory(auth, 'groups'),

  fetchRoleTypes() {
    return auth.get('/role_types').then(res => res.data);
  },

  fetchGroups(params = {}) {
    return auth.get('/groups', { params }).then(res => res.data);
  },

  fetchGroupsPreviewsBy(keys) {
    return auth
      .get('/groups/previews_by', { params: { keys: keys.join() } })
      .then(res => res.data);
  },

  fetchActions() {
    return auth.get('/actions').then(res => res.data);
  },

  /** @action GP_AA */
  bindActionsToGroup(groupKey, actionIds = []) {
    return auth.put(`/groups/${groupKey}/actions`, { actionIds });
  },

  /** @action RL_AG */
  bindGroupsToRole(roleKey, groupKeys = []) {
    return auth.put(`/roles/${roleKey}/groups`, { groupKeys });
  },

  // --- Accounts settings ---
  getSettings(id) {
    return auth.get(`/accounts/${id}/settings`).then(res => res.data);
  },
  putSettings(id, params) {
    return auth
      .put(`/accounts/${id}/settings`, { ...params })
      .then(res => res.data);
  },
  deleteSettings(id) {
    return auth.get(`/accounts/${id}/settings`).then(res => res.data);
  },
  getDisabled2fa() {
    return axios
      .get(`${AUTH_API}/passwords/disable2fa`)
      .then(data => data.data);
  },

  // --- QR code ---
  // QR_GET
  getQrcode(id) {
    return auth.get(`/accounts/${id}/qrcode`).then(res => res.data);
  },
  // QR_GEN
  postQrcode(id) {
    return auth.post(`/accounts/${id}/qrcode`).then(res => res.data);
  },
  // QR_DEL
  deleteQrcode(id) {
    return auth.delete(`/accounts/${id}/qrcode`).then(res => res.data);
  },
  getAppAccountsPreviews(params) {
    return auth.get('/apps', { params }).then(res => res.data);
  },

  // --- 2FA with Email Code ---
  // Создает код и отправляет на почту юзеру
  get2FAEmailCode(accessToken) {
    const headers = createAuthorizedHTTPHeader(accessToken, 'auth');

    return axios
      .get(`${AUTH_API}/my/2facode`, {
        headers,
      })
      .then(res => res.data);
  },
  // Проверяем введенный пользователем код
  post2FAEmailCode(code, accessToken) {
    const headers = createAuthorizedHTTPHeader(accessToken, 'auth');

    return axios
      .post(`${AUTH_API}/my/2facode_check`, { code }, { headers })
      .then(res => res.data);
  },
};
