import { DateTime } from '@/plugins/luxon';
import { HTTP_CODES } from '@/utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isObject';
import { traceIdGenerator } from '@/plugins/nanoid';

export function generateDomElementDescription(element) {
  const tag = element.tagName.toLowerCase();
  const classes = Array.from(element.classList).join('.');
  const id = element.id;

  return tag + (classes ? '.' + classes : '') + (id ? '#' + id : '');
}

/**
 * Returns current date with format year-month-day
 *
 * @param {string|null} date
 * @returns {string}
 */
export function currentDate(raw) {
  const date = DateTime.local();
  if (raw) return date;
  return date.toFormat('yyyy-MM-dd');
}

/**
 * Returns current DateTime obj
 *
 * @param {string|null} date
 * @returns {DateTime}
 */
export function currentDateTime() {
  return DateTime.local();
}

/**
 * Returns DateTime object from formats:
 *  - object DateTime of luxon
 *  - object Date of js
 *  - number milliseconds of unix epoch
 *  - string date year-month-day (ISO)
 *  - string date day.month.year (Russian)
 *
 * @param {string|null} date
 * @returns {DateTime}
 */
export function parseDate(date) {
  if (!date) return null;
  if (date instanceof DateTime) return date;
  if (date instanceof Date) return DateTime.fromJSDate(date);
  if (Number.isInteger(date)) return DateTime.fromMillis(date);

  let obj = DateTime.fromISO(date);
  if (!obj.isValid) {
    obj = DateTime.fromFormat(date, 'dd.MM.yyyy');
  }
  return obj;
}

/**
 * Compares two dates in different formats
 * For using in Array.sort(). Can sort dates with years and without
 *
 * See the list of available formats in function parseDate
 * @example ['09.06.2010', '25.05.2010', '22.06.2010'].sort(compareDates) -> ['25.05.2010', '09.06.2010', '22.06.2010']
 * @see parseDate
 *
 * @param {*} a first date (string/object)
 * @param {*} b second date (string/object)
 * @returns {number} difference between dates
 */
export function compareDates(a, b) {
  a = parseDate(a);
  b = parseDate(b);
  return a - b;
}

/**
 * Returns lowest date passed to the function
 * Removes all empty array elements (null, undefined) and invalid dates
 * See the list of available formats in function parseDate
 * @see parseDate
 *
 * @param  {...any} dates
 * @returns date
 */
export function getLowestDate(...dates) {
  return dates.sort(compareDates).filter(date => date)[0];
}

/**
 * Returns highest date passed to the function
 * Removes all empty array elements (null, undefined) and invalid dates
 * See the list of available formats in function parseDate
 * @see parseDate
 *
 * @param  {...any} dates
 * @returns date
 */
export function getHighestDate(...dates) {
  return dates
    .sort(compareDates)
    .filter(date => date)
    .slice(-1)[0];
}

/**
 * Calculates different between two dates in years
 *
 * @param {*} firstDate
 * @param {*} secondDate
 * @returns {number} difference in years
 */
export function diffYears(firstDate, secondDate) {
  firstDate = DateTime.fromJSDate(new Date(firstDate));
  secondDate = DateTime.fromJSDate(new Date(secondDate));

  const { years } = secondDate.diff(firstDate, 'year').toObject();

  return Math.floor(Math.abs(years));
}

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 * @example getProperty('a.b.c', {a: {b: {c: 'Hello!'}}}) -> 'Hello!'
 *
 * @param {string} path nested A dot notation style parameter reference (ie "urls.small")
 * @param {Object} object (optional) The object to search
 * @return the value of the property in question or undefined
 */
export function getProperty(path, object) {
  return path.split('.').reduce((acc, part) => acc && acc[part], object);
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function debug(prefix, backgroundColor) {
  return (...args) => {
    // eslint-disable-next-line no-console
    console.log(
      `%c[${prefix}]`,
      `background: ${backgroundColor}; color: white; padding: 4px;`,
      ...args,
    );
  };
}

export function calculateDuration(start, end) {
  const startDate = parseDate(start);
  const endDate = parseDate(end);
  const diffObject = endDate
    .diff(startDate, [
      'months',
      'days',
      'hours',
      'minutes',
      'seconds',
      'milliseconds',
    ])
    .toObject();

  return diffObject;
}

export function timeStringToSeconds(string) {
  // Начинаем с конца на случай, если будут отсутствовать элементы
  // Секунды есть всегда
  const [s = 0, m = 0, h = 0] = string.split(':').reverse();
  return +h * 3600 + +m * 60 + +s;
}

export function timeSecondsToString(seconds) {
  const withZeros = num => String(num).padStart(2, '0');

  let hours = null;
  let mins = Math.floor(seconds / 60);

  if (mins >= 60) {
    hours = Math.floor(mins / 60);
    mins = mins % 60;
  }
  const secs = seconds % 60;

  const time = `${withZeros(mins)}:${withZeros(secs)}`;
  return hours ? `${hours}:${time}` : time;
}

export function fileSize(count) {
  let val = count;
  let step = 0;
  const units = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];

  while (true) {
    const newVal = val / 1000;
    if (newVal > 1) {
      val = newVal;
      step++;
    } else break;
  }

  return `${val.toFixed(2)}${units[step]}`;
}

export function isXhrError(err) {
  return err?.isAxiosError || Boolean(err?.response?.status);
}

export function xhrErrorMessage(err) {
  if (/(Network Error)|(Failed to fetch)/gi.test(err?.message || err)) {
    return (
      'Ошибка сети.' +
      ' Проверьте интернет соединение или обратитесь в техническую поддержку'
    );
  }

  // instance of Axios Error
  if (err?.response) {
    if (
      String(err.response?.status)[0] === '5' &&
      HTTP_CODES[err.response?.status]
    )
      return HTTP_CODES[err.response?.status];

    // no show message 400 - validation
    if (
      [
        'Constraint validation failed!',
        'Constraint check failed!',
        'Validation failed!',
      ].includes(err.response?.data?.message)
    )
      return null;

    return (
      err.response?.data?.message || HTTP_CODES[err.response?.status] || null
    );
  }

  return err.message;
}

export function getServiceName(url = '') {
  return url.split(/\/api\/([^/?\s]+)/g)?.[1] || null;
}

export function generateColor(offset) {
  return (
    'hsl(' + (offset % 2 === 0 ? offset + 2 : offset - 2) * 30 + ', 50%, 45%)'
  );
}

export function numberWithSpaces(x) {
  if (Number.isNaN(x)) return x;
  const nbsp = ' '; // Неразрывный пробел - &nbsp;
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, nbsp);
}

// Replace empty strings and empty objects with null
export function deepCopyWithNull(obj) {
  if (typeof obj !== 'object' || obj === null) return obj;

  const result = cloneDeep(obj);

  Object.keys(result).forEach(key => {
    if (isPlainObject(result[key])) {
      // if no keys present in obje or if no values
      if (
        !Object.keys(result[key]).length ||
        !Object.values(result[key]).filter(item => item || item === 0).length
      )
        result[key] = null;
      // Recursively deep copy each property
      else result[key] = deepCopyWithNull(obj[key]);
    } else if (result[key] === '') result[key] = null;
  });

  return result;
}

export async function authWindowManager(url, field) {
  return new Promise((resolve, reject) => {
    const x = screen.width / 2 - 450 / 2;
    const y = screen.height / 2 - 600 / 2;
    const newWindow = window.open(
      url,
      'name',
      'height=600,width=450,left=' + x + ',top=' + y,
    );
    if (window.focus) {
      newWindow.focus();
    }
    const messageHandler = msg => {
      if (msg.data[field]) {
        resolve(msg.data[field]);
      } else {
        reject(msg.data);
      }
      newWindow.close();
    };
    window.addEventListener('message', messageHandler);
    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        window.removeEventListener('message', messageHandler);
        window.focus();
        reject(new Error('window was closed'));
      }
    }, 1000);
  });
}

export function createAuthorizedHTTPHeader(accessToken, serviceName) {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + accessToken,
    'X-Trace-Id': `web:${serviceName}:` + traceIdGenerator(),
  };
}
